<template>
    <v-card flat>
        <v-card-text class="pb-0 mb-0">
            <v-row>
                <v-col style="color:#4E87DD; font-size:26px; padding-top:60px; padding-bottom:30px;" class="text-center">결제정보 등록 처리에 실패하였습니다.</v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
    name: 'join_step_05_fail',
    computed: {
        ...mapGetters({
            joinTid: 'join/getJoinTid'
        })
    },
    data: () => ({
    }),
    mounted () {
        this.$store.commit('join/setJoinStep', 5)
        this.doNext()
    },
    methods: {
        doNext() {
            let va = this

            this.$store.dispatch('join/getJoinStepInfo', {
                param: {
                    'join_tid': va.$route.params.join_tid
                },
                scb: (va) => {
                    va.$router.push(`/join/step04/${va.$route.params.join_tid}`)
                },
                fcb: (err, va) => {
                    va.$router.push('/join/step01')
                }, va: this
            })
        }
    }
}
</script>
<style>
</style>